/*
 * @Author: Alan
 * @Date: 2023-06-14 15:11:45
 * @LastEditors: ‘alanfortest’ thisalantest@outlook.com
 * @LastEditTime: 2023-10-10 00:40:44
 * @Description: 域名管理
 * @FilePath: /F1-M1-WEB-Code/actions/Host.config.js
 */
// 初始化 Config 对象和 CMS_ID 变量
let Config = {};
let CMS_ID = 1;

//CMS api 原本写死的domain (目前用不到先保留，当前用动态获取)
export const STAGE_CMS_URL = "https://cmsapistag.fun88.biz";
export const SL_CMS_URL  = "https://cmsapisl.fun88.biz";
export const LIVE_CMS_URL = "https://cache.g32hjs.com";

// 导入 Domainparse 函数（从 "$ACTIONS/util" 模块中）
import { Domainparse } from '$ACTIONS/util';

// 检查是否在浏览器环境中运行
if (typeof global.location !== "undefined") {
    // 获取当前主机名，并检查其是否与任何测试 URL 匹配
    const LocalHost = global.location.host;
    const parsed = Domainparse(window.location.host);
    let STRAPI_CMS_URL = (parsed?.domain) ? `https://cache.${parsed.domain}` : LIVE_CMS_URL;

    //STG测试环境域名
    const StagingApi = Boolean(
        [
            "p5stag.fun88.biz",
            "p5stag1.fun88.biz",
            "p5stag2.fun88.biz",
            "p5stag3.fun88.biz",
            "p5stag4.fun88.biz",
            "p5stag5.fun88.biz",
            "p5stag6.fun88.biz",
            "p5stag7.fun88.biz",
            "p5stag8.fun88.biz",
            "p5stag9.fun88.biz",
            "p5stag10.fun88.biz",
            "p5stag11.fun88.biz",
            "p5stag12.fun88.biz",
            "p5stag13.fun88.biz",
            "p5stag14.fun88.biz",
            "p5stag15.fun88.biz",
            "p5stag16.fun88.biz",
            "localhost:8383",
            "localhost:5858",
            "127.0.0.1:8080",
        ].find((v) => global.location.href.includes(v))
    );
    //灰度环境测试域名
    const SlApi = Boolean(
        [
            "p5sl.fun88.biz",
            "p5sl1.fun88.biz",
            "p5sl2.fun88.biz",
            "p5sl3.fun88.biz",
            "p5sl4.fun88.biz",
            "p5sl5.fun88.biz",
            "p5sl6.fun88.biz",
            "p5sl7.fun88.biz",
            "p5sl8.fun88.biz",
            "localhost:6868",
        ].find((v) => global.location.href.includes(v))
    );
    // 如果在测试 URL 上，则使用测试环境设置
    if (StagingApi) {
        STRAPI_CMS_URL = LocalHost === "localhost:8383" ? `https://cache.p5stag.fun88.biz` :  `https://cache.${LocalHost}`;
        Config = {
            SportApi: "https://shsp1sp-gw.funpo.com:2041",
            // HostApi: "https://gateway-idcslf5.gamealiyun.com",
            HostApi: "https://gateway-idcstgf1p502.gamealiyun.com", //"https://gateway-idcstgf1p502.gamealiyun.com",
            BffscApi: "https://febff-api-staging-m1-instance01.fun88.biz",
            // BffscApi: "https://febff-api-staging-m1.fun88.biz",
            LocalHost: "https://p5stag.fun88.biz/",
            IMAccessCode: "cbe2c39c75137de4",
            seasonId: 24,
            StrapiCmsUrl: STRAPI_CMS_URL,
            isLIVE: false,
            isOnlyLIVE: false, // only true in Live 
            isGameLive: window.localStorage ? localStorage.getItem('forceIsGameLive') : false, //添加这个参数是为了区分线上和灰度 （线上游戏列表 只要 isLive = true 的游戏,灰度和测试环境不处理）
        };
    }
    // 如果在 SL Live URL 上，则使用灰度环境设置
    else if (SlApi) {
        //let tmp = [new Date().getFullYear(), new Date().getMonth(), new Date().getDate() ].join('');
        //if (tmp==='202484' || tmp==='202485' || tmp==='202486') {
        //    STRAPI_CMS_URL = `https://cmsapisl2.fun88.biz`;
        //} else {
        STRAPI_CMS_URL = `https://cache.${LocalHost}`;
        //}
        Config = {
            SportApi: "https://shslt1sp-gw.funpo.com:2041",
            HostApi: "https://gateway-idcslf5.gamealiyun.com", //"https://gateway-idcf5.fun963.com", //https://gateway-idcf5.fun1689.com
            BffscApi: "https://gatewaycnsl-scf1.fun88.biz",
            LocalHost: "https://p5sl.fun88.biz/",
            IMAccessCode: "85a983ec2611cc67",
            seasonId: 24,
			StrapiCmsUrl: STRAPI_CMS_URL,
            isLIVE: true,
            isOnlyLIVE: false, // only true in Live 
            isGameLive: false,
        };
    }
    // 否则，使用线上环境设置
    else {
        const LiveHostApi = `https://gateway-idcf5.${
            parsed.sld || "gamealiyun"
        }.${parsed.tld}`;
        Config = {
            SportApi: "https://shpt1sp-gw.funpo.com:2041",
            HostApi: LiveHostApi,
            BffscApi: "https://gatewaycn-scf1." + parsed.domain,
            IMAccessCode: "85a983ec2611cc67",
            seasonId: 24,
            LocalHost: "https://" + LocalHost + "/",
            StrapiCmsUrl: STRAPI_CMS_URL,
            isLIVE: true,
            isOnlyLIVE: true, // only true in Live 
            isGameLive: true,
        };
    }
}

// 将 Config 和 CMS_ID 导出为默认值
export default { Config, CMS_ID };
